import { offset } from '@floating-ui/react';
import { type SelectListboxSlotProps } from '@mui/base';
import { Option } from '@mui/base/Option';
import { Select } from '@mui/base/Select';
import { type SelectRootSlotProps } from '@mui/base/Select';
import cx from 'classnames';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { useController } from 'react-hook-form';
import type {
  Control,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { FormListbox } from '@components/formListbox/FormListbox';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './ControlledFormTimeRange.scss';
import { filterTimeOptions } from './filterTimeOptions';
import { LayoutVariant } from './sharedTypes';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface ControlledFormTimeRangeProps<
  T extends FieldValues = FieldValues,
  ToName extends FieldPath<T> = FieldPath<T>,
  FromName extends FieldPath<T> = FieldPath<T>,
> {
  control: Control<T>;
  groupLabel: string;
  variant?: LayoutVariant;
  toProps: {
    name: ToName;
    label: string;
    placeholder?: string;
    rules?: UseControllerProps<T, ToName>['rules'];
  };
  fromProps: {
    name: FromName;
    label: string;
    placeholder?: string;
    rules?: UseControllerProps<T, FromName>['rules'];
  };
  minTime?: string;
  maxTime?: string;
}

export const ControlledFormTimeRange = <
  T extends FieldValues = FieldValues,
  ToName extends FieldPath<T> = FieldPath<T>,
  FromName extends FieldPath<T> = FieldPath<T>,
>({
  control,
  groupLabel,
  variant,
  toProps,
  fromProps,
  minTime = '',
  maxTime = '',
}: ControlledFormTimeRangeProps<T, ToName, FromName>) => {
  const options = useMemo(
    () => filterTimeOptions(minTime, maxTime),
    [minTime, maxTime],
  );

  const {
    field: { onChange: fromOnChange, value: fromValue = '' },
    fieldState: { error: fromError },
  } = useController({
    control,
    name: fromProps.name,
    rules: fromProps.rules,
  });

  const {
    field: { onChange: toOnChange, value: toValue = '' },
    fieldState: { error: toError },
  } = useController({
    control,
    name: toProps.name,
    rules: toProps.rules,
  });
  const hasError = !!fromError || !!toError;

  return (
    <div
      className={cx(styles.container, {
        [styles.horizontal]: variant === LayoutVariant.Horizontal,
      })}
    >
      <label
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: hasError,
        })}
      >
        {groupLabel}
      </label>
      <div className={styles.inputsContainer}>
        <div>
          <Select
            aria-label={fromProps.label}
            aria-errormessage={`${fromProps.name}-error`}
            aria-invalid={!!fromError}
            id={`${fromProps.name}-select`}
            onChange={(_, value) => {
              fromOnChange(value);
            }}
            className={cx({
              [typography.t1]: true,
              [styles.select]: true,
              [styles.selectError]: !!fromError,
            })}
            slotProps={{
              popup: {
                disablePortal: true,
                middleware: [offset(8)],
                style: {
                  zIndex: 10,
                  width: '100%',
                  boxShadow: '0 16px 16px 0 #272f3e66',
                },
              },
            }}
            value={fromValue}
            slots={{
              root: SelectButton,
              listbox: SelectListbox,
            }}
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <ValidationErrorMessage
            error={fromError}
            label={fromProps.label}
            name={fromProps.name}
          />
        </div>
        <span className={cx(typography.t1, styles.to)} aria-hidden="true">
          to
        </span>
        <div>
          <Select
            aria-label={toProps.label}
            aria-errormessage={`${toProps.name}-error`}
            aria-invalid={!!toError}
            id={`${toProps.name}-select`}
            onChange={(_, value) => {
              toOnChange(value);
            }}
            className={cx({
              [typography.t1]: true,
              [styles.select]: true,
              [styles.selectError]: !!toError,
            })}
            slotProps={{
              popup: {
                disablePortal: true,
                middleware: [offset(8)],
                style: {
                  zIndex: 10,
                  width: '100%',
                  boxShadow: '0 16px 16px 0 #272f3e66',
                },
              },
            }}
            value={toValue}
            slots={{
              root: SelectButton,
              listbox: SelectListbox,
            }}
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <ValidationErrorMessage
            error={toError}
            label={toProps.label}
            name={toProps.name}
          />
        </div>
      </div>
    </div>
  );
};

// adds a chevron icon
const SelectButton = forwardRef(
  <TValue extends object, Multiple extends boolean>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, ownerState, ...rest } = props;
    return (
      <button type="button" {...rest} ref={ref}>
        {children}
        <Icon name="chevron" />
      </button>
    );
  },
);
SelectButton.displayName = 'SelectButton';

// wraps FormListBox and avoids forwarding MUI-Select-specific props
const SelectListbox = forwardRef(
  <OptionValue extends object, Multiple extends boolean>(
    props: SelectListboxSlotProps<OptionValue, Multiple>,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const { ownerState, ...attrs } = props;
    return <FormListbox {...attrs} ref={ref} />;
  },
);
SelectListbox.displayName = 'SelectListbox';
