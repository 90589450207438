import { kebabCase } from 'lodash-es';
import type { ReactNode } from 'react';
import type { IconNameType } from '@shared/types/floorPlans';
import type { FloorPlanTablesRenderer } from './FloorPlan';
import { InteractiveFloorPlanTable } from './InteractiveFloorPlanTable';
import { NonInteractiveFloorPlanTable } from './NonInteractiveFloorPlanTable';

export interface RendererFloorPlanTable {
  iconName: IconNameType;
  iconWidthScale: string;
  left: string;
  name: string;
  orientation: string;
  pulse?: boolean;
  top: string;
}

export interface InteractiveFloorPlanTablesRendererFactoryProps<
  T extends RendererFloorPlanTable,
> {
  showName?: boolean;
  calculateIsHighlighted?: (floorPlanTable: T) => boolean;
  calculateIsSelectable?: (floorPlanTable: T) => boolean;
  errorState?: boolean;
  handleTableOnClick?: (floorPlanTable: T) => void;
  tables: T[];
  unselectableTooltipText?: string;
}

export const interactiveFloorPlanTablesRendererFactory =
  <T extends RendererFloorPlanTable>({
    showName = false,
    calculateIsHighlighted = () => false,
    calculateIsSelectable = () => true,
    errorState,
    handleTableOnClick = (): void => undefined,
    tables,
    unselectableTooltipText,
  }: InteractiveFloorPlanTablesRendererFactoryProps<T>): FloorPlanTablesRenderer =>
  // eslint-disable-next-line react/display-name
  (tableIconScale: number): ReactNode => (
    <>
      {tables.map((floorPlanTable) => (
        <InteractiveFloorPlanTable
          showName={showName}
          errorState={errorState}
          handleTableOnClick={() => handleTableOnClick(floorPlanTable)}
          iconName={floorPlanTable.iconName}
          iconWidthScale={floorPlanTable.iconWidthScale}
          isHighlighted={calculateIsHighlighted(floorPlanTable)}
          isSelectable={calculateIsSelectable(floorPlanTable)}
          key={JSON.stringify(floorPlanTable)}
          left={floorPlanTable.left}
          name={floorPlanTable.name}
          orientation={floorPlanTable.orientation}
          pulse={floorPlanTable.pulse}
          tableIconScale={tableIconScale}
          testId={kebabCase(`floor-plan-table-${floorPlanTable.name}`)}
          top={floorPlanTable.top}
          unselectableTooltipText={unselectableTooltipText}
        />
      ))}
    </>
  );

export interface FloorPlanTablesRendererFactoryProps<
  T extends RendererFloorPlanTable,
> {
  calculateIsHighlighted?: (floorPlanTable: T) => boolean;
  tables: T[];
}

export const floorPlanTablesRendererFactory =
  <T extends RendererFloorPlanTable>({
    calculateIsHighlighted = () => false,
    tables,
  }: FloorPlanTablesRendererFactoryProps<T>): FloorPlanTablesRenderer =>
  // eslint-disable-next-line react/display-name
  (tableIconScale: number): ReactNode => (
    <>
      {tables.map((floorPlanTable) => (
        <NonInteractiveFloorPlanTable
          iconName={floorPlanTable.iconName}
          iconWidthScale={floorPlanTable.iconWidthScale}
          isHighlighted={calculateIsHighlighted(floorPlanTable)}
          key={JSON.stringify(floorPlanTable)}
          left={floorPlanTable.left}
          name={floorPlanTable.name}
          orientation={floorPlanTable.orientation}
          tableIconScale={tableIconScale}
          testId={kebabCase(`floor-plan-table-${floorPlanTable.name}`)}
          top={floorPlanTable.top}
        />
      ))}
    </>
  );
