import { Input } from '@mui/base';
import cx from 'classnames';
import { useId } from 'react';
import { useController } from 'react-hook-form';
import type {
  Control,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ControlledFormDateRange.scss';
import { LayoutVariant } from './sharedTypes';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface ControlledFormDateRangeProps<
  T extends FieldValues = FieldValues,
  ToName extends FieldPath<T> = FieldPath<T>,
  FromName extends FieldPath<T> = FieldPath<T>,
> {
  control: Control<T>;
  groupLabel: string;
  variant?: LayoutVariant;
  toProps: {
    name: ToName;
    label: string;
    rules?: UseControllerProps<T, ToName>['rules'];
  };
  fromProps: {
    name: FromName;
    label: string;
    rules?: UseControllerProps<T, FromName>['rules'];
  };
}

export const ControlledFormDateRange = <
  T extends FieldValues = FieldValues,
  ToName extends FieldPath<T> = FieldPath<T>,
  FromName extends FieldPath<T> = FieldPath<T>,
>({
  control,
  groupLabel,
  variant,
  toProps,
  fromProps,
}: ControlledFormDateRangeProps<T, ToName, FromName>) => {
  const id = useId();
  const groupLabelId = `group-label-${id}`;

  const {
    field: { onChange: fromOnChange, value: fromValue = '', ref: fromRef },
    fieldState: { error: fromError },
  } = useController({
    control,
    name: fromProps.name,
    rules: {
      ...fromProps.rules,
      validate: {
        ...fromProps.rules?.validate,
      },
    },
  });

  const {
    field: { onChange: toOnChange, value: toValue = '', ref: toRef },
    fieldState: { error: toError },
  } = useController({
    control,
    name: toProps.name,
    rules: {
      ...toProps.rules,
      validate: {
        ...toProps.rules?.validate,
      },
    },
  });
  const hasError = !!fromError || !!toError;

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    try {
      e.target.showPicker?.();
    } catch (onFocusError) {
      if (
        onFocusError instanceof DOMException &&
        onFocusError.name === 'NotAllowedError'
      ) {
        /* Ignore the NotAllowedError.
         *
         * Me and my bad, bad homies
         * Doin' bad, bad things
         * But it feels so good
         *
         * Source: pH-1, “Mr. Bad” (feat. Woo), 2022
         * */
      } else {
        throw onFocusError;
      }
    }
  };
  const handleOnMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.type = 'date';
    if ('showPicker' in e.currentTarget) e.currentTarget.showPicker();
  };
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) e.target.type = 'text';
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.horizontal]: variant === LayoutVariant.Horizontal,
      })}
    >
      <label
        id={groupLabelId}
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: hasError,
        })}
      >
        {groupLabel}
      </label>
      <div className={styles.inputsContainer}>
        <div>
          <span id="from-label-id" className={styles.visuallyHidden}>
            {fromProps.label}
          </span>
          <Input
            onChange={fromOnChange}
            onWheel={() => (document.activeElement as HTMLInputElement).blur()}
            slotProps={{
              input: {
                ref: fromRef,
                inputMode: 'none',
                'aria-labelledby': [groupLabelId, 'from-label-id'].join(' '),
                'aria-errormessage': `${fromProps.name}-error`,
                'aria-invalid': !!fromError,
                className: cx({
                  [typography.t1]: true,
                  [styles.inputError]: !!fromError,
                }),
                onFocus: handleOnFocus,
              },
            }}
            type="date"
            value={fromValue}
          />
          <ValidationErrorMessage
            error={fromError}
            label={fromProps.label}
            name={fromProps.name}
          />
        </div>
        <span className={cx(typography.t1, styles.to)} aria-hidden="true">
          to
        </span>
        <div>
          <span id="to-label-id" className={styles.visuallyHidden}>
            {toProps.label}
          </span>
          <Input
            onChange={toOnChange}
            onWheel={() => (document.activeElement as HTMLInputElement).blur()}
            placeholder="Endless"
            slotProps={{
              input: {
                ref: toRef,
                inputMode: 'none',
                'aria-labelledby': [groupLabelId, 'to-label-id'].join(' '),
                'aria-errormessage': `${toProps.name}-error`,
                'aria-invalid': !!toError,
                className: cx({
                  [typography.t1]: true,
                  [styles.inputError]: !!toError,
                }),
                onBlur: handleOnBlur,
                onMouseDown: handleOnMouseDown,
                onFocus: handleOnFocus,
              },
            }}
            type={toValue ? 'date' : 'text'}
            value={toValue}
          />
          <ValidationErrorMessage
            error={toError}
            label={toProps.label}
            name={toProps.name}
          />
        </div>
      </div>
    </div>
  );
};
